import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { text: String, rotateInterval: Number };
  static targets = ["text"];

  connect() {
    this.timerId = null;
    this.currentItem = 0;

    if (this.textValue && this.rotateIntervalValue) {
      const interval = Math.floor(this.rotateIntervalValue * 1000);
      this.setupTimer(
        interval,
        this.textValue.split(",").map((text) => text.trim()),
        this.currentItem
      );
    }
  }

  disconnect() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  setupTimer(interval, values, itemIdx) {
    this.textTarget.textContent = values[itemIdx];
    if (interval > 0) {
      this.timerId = setTimeout(() => {
        let nextItem = itemIdx + 1;
        if (nextItem >= values.length) {
          nextItem = 0;
        }
        this.setupTimer(interval, values, nextItem);
      }, interval);
    }
  }
}
